import React, {useState, useEffect} from "react";
import Box from '@mui/material/Box';
import { InputLabel, Typography } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Popup from 'reactjs-popup';
// import Popup from './Popup';
import 'reactjs-popup/dist/index.css';
import Modal from '@mui/material/Modal';
// import { blur } from '@mui/system';

import '../assets/styles/form.css';
import '../assets/styles/global.css';
import ScreenView from "../util/screenView";
import { Navigate, useNavigate } from "react-router-dom";
import { CategoryList, CourseList, Enquiry, Form, NewEnquiry } from "../util/apiUrl";
import CallAPI from '../util/apiAction';
import { Opacity } from "@mui/icons-material";

export default function CourseSelection(props){
    const Navigate = useNavigate();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 400,
        // bgcolor: 'background.paper',
        // border: '2px solid #000',
        // boxShadow: 24,
        // p: 4,
      };

    // useState
    const [degreeError, setDegreeErrorMessage] = useState("");
    const [courseError, setCourseErrorMessage] = useState("");

    const [inpVal, setInpVal] = useState ({
        degree: null,
        course: null

    })

    // const [category, setCategory] = useState(null);
    // const [course, setCourse] = useState(null);
    const [enquiry, setEnquiry] = useState(props.enquiryData);
    const [enquiryId, setEnquiryId] = useState(null);
    const [userName, setUserName] = useState(null);
    const [data, setData] = useState(props.data);
    const [formData, setFormData] = useState(null);
    const [lastActiveSection, setLastActiveSection] = useState(null);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [dataFetched, setDataFetched] = useState(props.dataFetched);

    // const univId = sessionStorage.getItem('univID')
    const name = sessionStorage.getItem('name')
    const univId = sessionStorage.getItem('univID')
    const dateOfBirth = sessionStorage.getItem('dateOfBirth')
    const mobileNo = sessionStorage.getItem('mobileNo')
    const admissionId = sessionStorage.getItem('admissionId')

    useEffect(() =>{
        if(props.enquiryData !== null){
            setEnquiry(props.enquiryData)
        }
        if(props.data !== null){
            setData(props.data)
        }
        if(props.dataFetched !== null){
            setDataFetched(props.dataFetched)
        }
    },[props])


    // API to check if enquiry exist
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const { data,load,err } = await CallAPI(Enquiry, 'POST', { dateOfBirth:dateOfBirth, mobileNo:mobileNo, univId:univId }); 
    //             setEnquiry(data);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };
    
    //     // if(sessionStorage.getItem('source') !== 'digiversity'){
    //         fetchData(); 
    //     // }
        
    // }, []);

    // API of form
    // useEffect(()=>{    
    //     if(enquiry !== null ){
    //         let catId = enquiry.existingEnqDet.map((enq) => enq.categoryId).shift();
    //         let CourseId = enquiry.existingEnqDet.map((enq) => enq.courseId).shift();
    //         let enquiryId = enquiry.existingEnqDet.map((enq) => enq.enquiryId).shift();
    //         let userName = enquiry.existingEnqDet.map((enq) => enq.candidateName).shift();
    //         let admissionId = enquiry.existingEnqDet.map((enq) => enq.admissionId).shift();

    //         const fetchData = async () => {
    //         try {
    //             const { data,loading,error } = await CallAPI(Form, 'POST', { 
    //                 "unvId": univId,
    //                 "categoryId": catId,
    //                 "courseId": CourseId,
    //                 "enquiryId": enquiryId,
    //                 "mobileNo": mobileNo,
    //                 "dob": dateOfBirth,
    //                 "name": userName
    //             }
    //             ); 
    //              setData(data);
    //             sessionStorage.setItem('lastActiveSection', parseInt(data.lastActiveSection))
    //             sessionStorage.setItem('admissionId', admissionId)
    //             setDataFetched(true)
    //             } catch (error) {
    //                 console.error('Error fetching data:', error);
    //             }
    //         };
        
    //         fetchData();
    //     }
        
    // }, [enquiry])
    
    // Course list API if enquiry exist
    useEffect(() => {
        if (enquiry?.existingEnqDet && Array.isArray(enquiry.existingEnqDet) && sessionStorage.getItem('source') !== 'digiversity') {
            const catId = enquiry.existingEnqDet.map((enq) => enq.categoryId).shift();
            const courseId = enquiry.existingEnqDet.map((enq) => enq.courseId).shift();

            // Set the category value once outside of the map function
            setInpVal(prevInpVal => ({
                ...prevInpVal,
                degree: catId,
                course: courseId 
            }));

            if(enquiry.existingEnqDet.length !== 0){
                handleOpen()
                setEnquiryId(enquiry.existingEnqDet.map((enq) => enq.enquiryId).shift())
                setUserName(enquiry.existingEnqDet.map((enq) => enq.candidateName).shift())
                sessionStorage.setItem('enquiryId', parseInt(enquiry.existingEnqDet.map((enq) => enq.enquiryId).shift()))
            }else{
                setOpen(false)
            }
        }else if(enquiry !== null && sessionStorage.getItem('source') === 'digiversity'){
            let category = sessionStorage.getItem('CategoryId')
            let courseSess = sessionStorage.getItem('CourseId')
    
            setInpVal((prevInpVal) => ({
                ...prevInpVal,
                degree: parseInt(category),
                course: parseInt(courseSess),
            }));
        }
    
    }, [enquiry])

    const handleChange = (e) => {
        // console.log(e);
        const { value, name } = e.target;
        setInpVal((prevInpVal) => ({
            ...prevInpVal,
            [name]: value
        }));

        if(inpVal.degree !== ""){
            setDegreeErrorMessage("")
        }
        if(inpVal.course !== ""){
            setCourseErrorMessage("")
        }
    }
    
    const catId = inpVal.degree;
    const CourseId = inpVal.course;

    // Proceed to pre-requisite
    const handleProceedClick = async() => {
        try {
            const { data,loading,error } = await CallAPI(NewEnquiry, 'POST', { 
                "unvId": univId,
                "categoryId": catId.toString(),
                "courseId": CourseId.toString(),
                "mobileNo": mobileNo,
                "DateOfBirth": dateOfBirth,
                "name": name
            }); 
            sessionStorage.setItem('enquiryId', data.enquiryId)
            

        } catch (error) {
            console.error('Error fetching data:', error);
        }

        // set category and course in session
        if((catId !== null || catId !== undefined ) && (CourseId !== null || CourseId !== undefined)){
            sessionStorage.setItem("CategoryId",parseInt(catId));
            sessionStorage.setItem("CourseId",parseInt(CourseId));
        }

        // Set error message
        if(inpVal.degree === "" || inpVal.degree === undefined){
            setDegreeErrorMessage("Please select degree")
        }else{
            setDegreeErrorMessage("")
        } 

        if(inpVal.course === "" || inpVal.course === undefined){
            setCourseErrorMessage("Please select course")
        }else{
            setCourseErrorMessage("")
        }

        if (inpVal.degree !== "" && inpVal.degree !== undefined && inpVal.course !== "" && inpVal.course !== undefined) {
            const existingEnquiry = enquiry.existingEnqDet.find((enq) => {
              return enq.categoryId === inpVal.degree && enq.courseId === inpVal.course;
            });
          
            if (existingEnquiry && existingEnquiry.admissionId !== 0) {
                sessionStorage.setItem('admissionStage', existingEnquiry.admissionStage)
                Navigate("/preview");
            } else {
                sessionStorage.setItem('admissionStage', null)
                try {
                    const { data,loading,error } = await CallAPI(Form, 'POST', { 
                        "unvId": univId,
                        "categoryId": parseInt(catId),
                        "courseId": parseInt(CourseId),
                        "enquiryId": parseInt(sessionStorage.getItem('enquiryId')),
                        "mobileNo": mobileNo,
                        "dob": dateOfBirth,
                        "name": name
                    }
                    ); 
                     setData(data);
                     sessionStorage.setItem('lastActiveSection', parseInt(data.lastActiveSection))
                     
                } catch (error) {
                    console.error('Error fetching data:', error);
                }

                Navigate('/pre-requisites')
            
            }
        }
        // if(inpVal.degree !=="" && inpVal.degree !== undefined && inpVal.course !== "" && inpVal.course !== undefined){
        //     console.log("in")
        //     const enquiryCatId = enquiry.existingEnqDet.map((enq) => enq.categoryId).shift()
        //     const enquiryCourseId = enquiry.existingEnqDet.map((enq) => enq.courseId).shift()
        //     let admissionId = enquiry.existingEnqDet.map((enq) => enq.admissionId).shift()
        //     sessionStorage.setItem('admissionId', admissionId)

        //     // If enquiry exist and categoryId and courseId is same as existing enquiry
        //     if(inpVal.degree === enquiryCatId && inpVal.course === enquiryCourseId){
        //         if(admissionId === 0){
        //             Navigate("/admission-form")
        //         }else{
        //             Navigate("/preview")
        //         }
                
        //         // Navigate("/admission-form", { state: { lastActiveSection: lastActiveSection, CategoryId: catId, CourseId:CourseId, enquiryId: enquiryId, name: userName } })
        //     }else{
        //         let enquiryCatId = enquiry.existingEnqDet.map((enq) => enq.categoryId).pop()
        //         let enquiryCourseId = enquiry.existingEnqDet.map((enq) => enq.courseId).pop()
        //         let admissionId = enquiry.existingEnqDet.map((enq) => enq.admissionId).pop()

        //         console.log('enquiryCatId, enquiryCourseId, admissionId', enquiryCatId, enquiryCourseId, admissionId)

        //         try {
        //             const { data,loading,error } = await CallAPI(Form, 'POST', { 
        //                 "unvId": univId,
        //                 "categoryId": parseInt(catId),
        //                 "courseId": parseInt(CourseId),
        //                 "enquiryId": parseInt(sessionStorage.getItem('enquiryId')),
        //                 "mobileNo": mobileNo,
        //                 "dob": dateOfBirth,
        //                 "name": name
        //             }
        //             ); 
        //              setData(data);
        //              console.log('data.lastActiveSection', data.lastActiveSection)
        //             //  localStorage.setItem('lastActiveSection', parseInt(data.lastActiveSection))
        //              sessionStorage.setItem('lastActiveSection', parseInt(data.lastActiveSection))
                     
        //             //  setFormData(data)
        //             //  setEnquiryId(data.enquiryId)
        //         } catch (error) {
        //             console.error('Error fetching data:', error);
        //         }
        //         console.log('enquiry in course: ', enquiryId)

        //         if(inpVal.degree === enquiryCourseId && inpVal.course === enquiryCatId){
        //             if(!(enquiryCatId === null || enquiryCatId === undefined) && !(enquiryCourseId === null || enquiryCourseId === undefined) && !(admissionId === 0 || admissionId === undefined)){
        //                 Navigate("/preview")
        //             }else{
        //                 Navigate("/pre-requisites")
        //             }
        //         }else{
        //             Navigate('/pre-requisites')
        //         }
                
                
        //         // Navigate("/pre-requisites", { state: { lastActiveSection: lastActiveSection, CategoryId: catId, CourseId:CourseId, name: name } });
        //     }
            
        //     // If enquiry doesn't exist
        //     // if(enquiry.existingEnqDet.length === 0){
        //     //     Navigate("/pre-requisites", { state: { lastActiveSection: lastActiveSection, CategoryId: catId, CourseId:CourseId,  enquiryId: enquiryId, name: userName } });
        //     // }else{
        //     //     const enquiryCatId = enquiry.existingEnqDet.map((enq) => enq.categoryId).pop()
        //     //     const enquiryCourseId = enquiry.existingEnqDet.map((enq) => enq.courseId).pop()

        //     //     // If enquiry exist and categoryId and courseId is same as existing enquiry
        //     //     if(inpVal.degree === enquiryCatId && inpVal.course === enquiryCourseId){
        //     //         Navigate("/admission-form", { state: { lastActiveSection: lastActiveSection, CategoryId: catId, CourseId:CourseId, enquiryId: enquiryId, name: userName } })
        //     //     }else{
        //     //         Navigate("/pre-requisites", { state: { lastActiveSection: lastActiveSection, CategoryId: catId, CourseId:CourseId,  enquiryId: enquiryId, name: userName } });
        //     //     }
        //     // }
            
        // }
        
    };

    // If enquiry exist continue to last active section
    const handleContinue = async() => {
        if((catId !== null || catId !== undefined ) && (CourseId !== null || CourseId !== undefined)){
            sessionStorage.setItem("CategoryId",parseInt(catId));
            sessionStorage.setItem("CourseId",parseInt(CourseId));
        }

        const existingEnquiry = enquiry.existingEnqDet.find((enq) => {
            return enq.categoryId === inpVal.degree && enq.courseId === inpVal.course;
        });
        
        if(dataFetched === true){
            if (existingEnquiry && existingEnquiry.admissionId !== 0) {
                sessionStorage.setItem('admissionId', existingEnquiry.admissionId)
                sessionStorage.setItem('admissionStage', existingEnquiry.admissionStage)
                Navigate("/preview")
            }else{
                sessionStorage.setItem('admissionStage', null)
                Navigate("/admission-form")
            }
        }
        
    } 

    return(
        <ScreenView>
            {mobileView => (
                <Box>
                    {inpVal.degree !== null && inpVal.course !== null && sessionStorage.getItem('source') !== 'digiversity' && (
                        <Modal
                            open={open}
                            onClose={handleClose}
                            // aria-labelledby="modal-modal-title"
                            // aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                            <Box className='modal' id='existing_enquiry_popup' sx={{
                                    width: '100%',
                                    height: '30%',
                                    borderRadius: '10px',
                                    // background: '#FFB627',
                                    background: '#fff',
                                    alignItems: 'right',
                                    justifyItems: 'right',
                                    textAlign: 'right',
                                    padding: mobileView ? '3%' : '1%',
                                    display: 'inline-block',
                                    // marginLeft: '20%'
                                }}
                            >
                                <CancelOutlinedIcon 
                                    sx={{textAlign:"right", background: '#fff', borderRadius: '4'}}
                                    onClick={() => setOpen(false)}   
                                />
                                <Typography sx={{textAlign: 'center', color: '#484848'}}>Hi <b>{enquiry.existingEnqDet.map((enq) => enq.candidateName).shift()}</b>, 
                                    you have selected <b>{enquiry.existingEnqDet.map((enq) => enq.category).shift()} </b>
                                    in <b>{enquiry.existingEnqDet.map((enq) => enq.course).shift()}. </b>
                                    Please continue to fill up the application form.
                                </Typography>
                                {/* <Typography sx={{textAlign: 'center', color: '#D4273C'}}>Applications will be closed on 03/05/2024.</Typography> */}
                                <Box sx={{textAlign: 'center'}}>
                                    <Button 
                                        sx={{
                                            border: '1px solid #D4273C',
                                            color: '#D4273C',
                                            background: '#FFF',
                                            marginTop:  '3%',
                                            marginLeft: '0%',
                                            marginBottom: '2%',
                                            '&:hover': {
                                                border: '1px solid #D4273C',
                                                background: '#FFF',
                                            },
                                            textTransform: 'none'
                                        }} 
                                        className="secondary-save-btn" 
                                        id='another_program'
                                        variant="outlined"
                                        onClick={() => 
                                            {
                                            //     setInpVal(prevInpVal => ({
                                            //     ...prevInpVal,
                                            //     degree: '',
                                            //     course: '' 
                                            // }));
                                            // document.getElementById('enquiry_alert').style.display = 'none';
                                            setOpen(false);
                                        }}
                                    >
                                        Select another program
                                    </Button>
                                    <Button sx={{
                                            background: '#D4273C',
                                            marginTop:  '3%',
                                            marginLeft: mobileView? '0%' : '5%',
                                            marginBottom: '2%',
                                            '&:hover': {
                                                background: '#D4273C',
                                            },
                                            textTransform: 'none'
                                        }} 
                                        className="primary-save-btn" 
                                        id='continue_app'
                                        variant="contained" 
                                        onClick={handleContinue}
                                    >
                                        Continue to fill up application
                                    </Button>
                                </Box>
                            </Box>
                            </Box>
                        </Modal>                
                    )}
                    <Typography className="title"
                        sx={{
                            fontSize: mobileView ? 16 : 30,
                            my: 3,
                            textAlign: 'left',
                            padding: '10px 50px 10px 50px',
                        }}
                    >
                        Course Selection
                    </Typography>
                    <Box className='block' sx={{padding: mobileView ? '20px 20px 20px 50px' : '20px 50px 20px 50px'}}>
                        {/* <Typography sx={{fontWeight: 600,}} varient='h6'>Enrolment Information</Typography> */}
                        <InputLabel className="label">Degree</InputLabel>
                        <Select
                            value={inpVal.degree || ''}
                            onChange={handleChange}
                            // onBlur={showCourse}
                            className="dropdown"
                            id='degree'
                            name="degree"
                            MenuProps={{ PaperProps: { sx: { maxHeight: '25%', maxWidth: '65%' } } }}   // height and width of dropdown menu
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{
                                width: mobileView ? '80%' : '40%'
                            }}
                            >
                            {/* {category !== null? 
                                category.categories.map((cat)=>(
                                    <MenuItem key={cat.categoryId} value={ cat.categoryId}>
                                        {cat.categoryName}
                                    </MenuItem>
                                ))
                            : null } */}
                            <MenuItem value="">
                                ---Select Degree---
                            </MenuItem>
                            {enquiry !== null &&
                                (enquiry.category_CourseDet).data.categories.map((cat)=>(
                                    (cat.isActive === 'Y' &&
                                        <MenuItem key={cat.categoryId} value={ cat.categoryId}>
                                            {cat.categoryName}
                                        </MenuItem>
                                    )
                                ))
                            
                            }

                        </Select>
                        {degreeError && <Typography className="error">{degreeError}</Typography>}
                        <InputLabel className="label">Course</InputLabel>
                        <Select
                            value={inpVal.course || ''}
                            onChange={handleChange}
                            className="dropdown"
                            id='course'
                            name="course"
                            MenuProps={{ PaperProps: { sx: { maxHeight: '25%', maxWidth: '65%', overflowX: 'auto' } } }}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{
                                width: mobileView ? '80%' : '40%'
                            }}
                            >
                            {/* {course !== null ? 
                                course.map((course)=>(
                                    <MenuItem value={course.courseId}>
                                        {course.courseName}
                                    </MenuItem>
                                ))
                            : null } */}
                            <MenuItem value="" >
                                ---Select Course---
                            </MenuItem>
                            {enquiry !== null &&
                                (enquiry.category_CourseDet).data.categories.map((cat)=>(
                                    (cat.isActive === 'Y' && cat.categoryId === catId &&
                                        cat.courses.map((cr) => (
                                            (cr.isActive === 'Y' &&
                                                <MenuItem value={cr.courseId}>
                                                    {cr.courseName}
                                                </MenuItem>
                                            )
                                        ))
                                    )
                                ))
                            
                            }
                        </Select>
                        {courseError && <Typography className="error">{courseError}</Typography>}
                    </Box>

                    <Box sx={{my:5, textAlign: mobileView? 'center' : 'left', display: mobileView ?? 'flex', justifyContent: mobileView ?? 'center'}}>
                        <Button sx={{
                                background: '#D4273C',
                                marginTop: mobileView ? '5%' : '0%',
                                marginLeft: mobileView ? '0%' : '12%',
                                '&:hover': {
                                    background: '#D4273C',
                                },
                                textTransform: 'none'
                            }} 
                            className="primary-save-btn" 
                            id="proceed"
                            variant="contained" 
                            onClick={handleProceedClick}
                            >
                            Proceed
                        </Button>
                    </Box>
                    
                </Box>
            )}
        </ScreenView>
    )
}