import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { Navigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import HyperLink from '@mui/material/Link';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
// import { useAuth } from '../util/AuthContext';
// import '../assets/styles/global.css';

import { GenerateOtp, ValidateOtp, UniversityDetails, ValidateCaptcha } from '../util/apiUrl';
import CallAPI from '../util/apiAction';

const year = dayjs().subtract(15, 'year');

export default function LoginContainer() {
    // const univID = 45;

    const [univID, setUnivID] = useState(null);
    const [captchaEnable, setCapchaEnable] = useState(0);
    const [captchaDetails, setCaptchaDetails] = useState(null);
    console.log(captchaDetails)
    const [validateOtpData, setValidateOtpData] = useState(null);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [otpVerified, setOtpVerified] = useState(false);
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [state, setState] = useState({
        mobileView: false,
    });

    // To set error message
    const [nameErrorMessage, setNameErrorMessage] = useState('')
    const [mobileErrorMessage, setMobileErrorMessage] = useState('')
    const [dateErrorMessage, setDateErrorMessage] = useState('')
    const [otpErrorMessage, setOtpErrorMessage] = useState(null)
    const [captchaErrorMessage, setCaptchaErrorMessage] = useState(null)

    // To set fields date, age, otp field
    const [selectedDate, setSelectedDate] = useState(null);
    const [age, setAge] = useState(null);
    const [otp, setOtp] = useState('');
    const [data, setData] = useState('');

    // Check for responsive
    const { mobileView } = state;
    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        }
    }, []);

    useEffect(() => {
        const importCSS = async () => {
            if (mobileView) {
                const module = await import('../assets/styles/mobile/global.css');
            } else {
                const module = await import('../assets/styles/global.css');
            }
        };

        importCSS();

    }, [mobileView]);

    useEffect(() => {
        if (sessionStorage.length > 0) {
            sessionStorage.removeItem('dateOfBirth')
            sessionStorage.removeItem('CourseId')
            sessionStorage.removeItem('source')
            sessionStorage.removeItem('enquiryId')
            sessionStorage.removeItem('CategoryId')
            sessionStorage.removeItem('mobileNo')
            sessionStorage.removeItem('loggedInUser')
            sessionStorage.removeItem('name')
            sessionStorage.removeItem('lastActiveSection')
            sessionStorage.removeItem('univID')
            localStorage.removeItem('RenderFromPreview')
        }
    }, [])

    const UniversityUrl = window.location.origin;

    useEffect(() => {
        const fetchData = async () => {
            let url = `${UniversityDetails}?UmsUniversityUrl=${UniversityUrl}`;
            if(UniversityUrl === 'http://localhost:3000'){
                url = `${UniversityDetails}?UmsUniversityUrl=https://mservicedev.teamleaseedtech.com`
            }
            try{
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    // body: JSON.stringify({ UmsUniversityUrl:UniversityUrl }),
                });
                if(response.ok){
                    const data = await response.json();
                    sessionStorage.setItem("univID",data.data[0].UnvId);
                    setUnivID(data.UnvId)
                    setCapchaEnable(data.data[0].LoginCaptchaEnable)
                }else{
                    console.log("otp not generated")
                }
            }catch(error){
                console.log(error);
            }
        };

        if(UniversityUrl !== null){
            fetchData();
        }

    }, [UniversityUrl]);

    // useEffect(() => {
    //     setUnivID(45)
    //     sessionStorage.setItem("univID", 45);
    // }, [])

    


    // Validation of date field
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setDateErrorMessage('');
        // calculateAge(date);
    };

    function formatDate(selectedDate) {
        const d = new Date(selectedDate);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    var dob = formatDate(selectedDate);

    const differenceInYears = (today, birthDate) => {
        let calculatedAge = today.getFullYear() - birthDate.getFullYear();
        let monthDifference = today.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            calculatedAge--;
        }
        return calculatedAge;
    }

    const calculateAge = (date) => {
        if (date) {
            // const currentDate = today;
            // const birthDate = birth;
            const currentDate = new Date();
            const birthDate = new Date(date);
            let calculatedAge = currentDate.getFullYear() - birthDate.getFullYear();
            const monthDifference = currentDate.getMonth() - birthDate.getMonth();
            if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())) {
                calculatedAge--;
            }
            // setAge(calculatedAge);
            // return calculatedAge;

        } else {
            setAge(null);
        }
    };

    // To set name and mobile number field
    const [inpVal, setInpVal] = useState({
        full_name: "",
        mobile: ""

    })
    const mobileNo = inpVal.mobile
    const [mobile, setMobile] = useState('');

    // Validation of name and mobile number   
    const check = (event) => {
        const { value, name } = event.target;
        setInpVal((prevInpVal) => ({
            ...prevInpVal,
            [name]: value
        }));

        if (name === 'full_name') {
            let regex = /^[A-Za-z\s'-]+$/;
            if (!regex.test(inpVal.full_name.trim())) {
                setNameErrorMessage('Enter a valid full name');
            } else {
                setNameErrorMessage('');
            }
        }
        if (name === 'mobile') {
            const newValue = event.target.value.replace(/[^\d]/g, '').replace('e', '');

            // Update the input value and ensure it doesn't exceed maxLength
            event.target.value = newValue.slice(0, 10);
            let mobileNumber = event.target.value;
            let regex = /^\d{0,10}$/;
            if (!regex.test(mobileNumber) || mobileNumber.length !== 10) {
                setMobileErrorMessage('Enter a valid mobile number');
            } else {
                setMobileErrorMessage('');
            }

        }


    }
    const [tempOtp, setTempOtp] = useState("");
    const [resendTempOtp, setResendTempOtp] = useState("");
    const [timeout, setTimeout] = useState(null);
    const [countdown, setCountdown] = useState(120);
    const [isTimerRunning, setIsTimerRunning] = useState(false);

    useEffect(() => {
        let timerId;
        if (isTimerRunning) {
          timerId = setInterval(() => {
            setCountdown((prevCountdown) => {
              if (prevCountdown > 0) {
                return prevCountdown - 1; // Decrease countdown by 1 second
              } else {
                clearInterval(timerId); // Clear the timer when countdown reaches 0
                setIsTimerRunning(false);
                return 0;
              }
            });
          }, 1000); // Update countdown every second
        }
        return () => {
          clearInterval(timerId);
        };
    }, [isTimerRunning]);

    // Validation to display otp block
    const showOtpField = async () => {
        if (inpVal.mobile.trim() === "") {
            setMobileErrorMessage('Mobile number is required');
        } if (inpVal.full_name.trim() === "") {
            setNameErrorMessage('Full name is required');
        } if (!selectedDate) {
            setDateErrorMessage('Date of birth is required')
        } if (captchaErrorMessage !== ''){
            setCaptchaErrorMessage('')
        }
        if (selectedDate) {
            const today = new Date();
            const birthDate = new Date(selectedDate);
            const yearsDiff = differenceInYears(today, birthDate);
            // setAge(yearsDiff);
            if (isNaN(yearsDiff)) {
                setDateErrorMessage('Invalid Date of birth');
            } else if (yearsDiff < 15) {
                setDateErrorMessage('You should be minimum 15 years old at the time of admission.');
            }
            else if (inpVal.full_name.trim() !== "" && inpVal.mobile.trim() !== "" && selectedDate && dateErrorMessage === '' && nameErrorMessage === '' && mobileErrorMessage === '') {
                setMobileErrorMessage('');
                setNameErrorMessage('');
                // setDateErrorMessage('')
                setCountdown(120);
                let block = document.getElementById('otpBlock');
                block.style.display = 'block';

                let timerBlock = document.getElementById('timer');
                timerBlock.style.display = 'block';

                // trigger api to generate otp
                try {
                    const response = await fetch(GenerateOtp, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ dateOfBirth: dob, mobileNo, univID, captchaFlag: captchaEnable }),
                    });
                    if (response.ok) {
                        const data = await response.json();
                       
                        setCaptchaDetails(data.data.captchaDetails)
                        console.log(data)
                        console.log(data.data.captchaDetails.attempts,"<---------attempts")
                        setIsTimerRunning(true);
                        setCountdown(120);
                        // const timer = setInterval(() => {
                        //     setCountdown((prevCountdown) => {
                        //         if (prevCountdown > 0) {
                        //             return prevCountdown - 1; // Decrease countdown by 1 second
                        //         } else {
                        //             clearInterval(timer); // Clear the timer when countdown reaches 0
                        //             setTimeout(1);
                        //             return 0;
                        //         }
                        //     });

                        // }, 1000); // Update countdown every second
                    } else {
                        console.log("otp not generated")
                    }
                } catch (error) {
                    console.log(error);
                }

            }
        }

    }
    const minutes = String(Math.floor(countdown / 60)).padStart(2, '0');
    const seconds = String(countdown % 60).padStart(2, '0');

    const Navigate = useNavigate();
    // validation of otp 
    const validateOtp = async (otpVal) => {
        setOtp(parseInt(otpVal));
        if (otpVal.length < 4) {
            setOtpErrorMessage('OTP is required.');
        } else {
            setOtpErrorMessage('');
            // if (otpVal === 1111) {
            //     Navigate("/course-selection");
            // }
            // validate otp
            try {
                const response = await fetch(ValidateOtp, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ dateOfBirth: dob, mobileNo: mobileNo, otp: parseInt(otpVal), univID }),
                });
                if (response.ok) {
                    const data = await response.json();
                    setValidateOtpData(data)
                    
                    // setTempOtp(data.data)
                    if (data.isSuccess && captchaEnable === 0) {
                        // redirect
                        let block = document.getElementById('timer');
                        block.style.display = 'none';
                        Navigate("/course-selection");
                    } else if (data.isSuccess === false && captchaEnable === 0) {
                        setOtpErrorMessage(data.message)
                    } else if (data.isSuccess && captchaEnable === 1){
                        // setOtpVerified(true)
                        let block = document.getElementById('timer');
                        block.style.display = 'none';

                        let captchaBlock = document.getElementById('captcha_block')
                        captchaBlock.style.display = 'block'
                    }
                } else {
                    setOtpErrorMessage('Invalid OTP')
                }
            } catch (error) {
                console.log(error);
            }

            // Store data using session
            sessionStorage.setItem("name", inpVal.full_name);
            sessionStorage.setItem("mobileNo", inpVal.mobile);
            sessionStorage.setItem("dateOfBirth", dob);
            // sessionStorage.setItem("univID",45);
            sessionStorage.setItem("loggedInUser", 'student');
            sessionStorage.setItem("source", "admission")

        }
    }

    const resendOtp = async () => {
        setOtpErrorMessage('');
        setTimeout(null)
        // setCountdown(120); // set countdown to 2 mintues

        // trigger api for generate otp
        try {
            const response = await fetch(GenerateOtp, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ dateOfBirth: dob, mobileNo, univID, captchaFlag: captchaEnable, refreshCaptchaFlag: 1 }),
            });

            if (response.ok) {
                const data = await response.json();
                setTempOtp(data.data)
                setIsTimerRunning(true);
                setCountdown(120);
                // const timer = setInterval(() => {
                //     setCountdown((prevCountdown) => {
                //         if (prevCountdown > 0) {
                //             return prevCountdown - 1; // Decrease countdown by 1 second
                //         } else {
                //             clearInterval(timer); // Clear the timer when countdown reaches 0
                //             setTimeout(1);  // set timeout to 1 to display resend button
                //             return 0;
                //         }
                //     });

                // }, 1000); // Update countdown every second

            } else {
                console.log("otp not generated")
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        let mobileNo = sessionStorage.getItem('mobileNo')
        let name = sessionStorage.getItem('name')
        let dob = sessionStorage.getItem('dateOfBirth')

        setInpVal((prevInpVal) => ({
            ...prevInpVal,
            full_name: name || '',
            mobile: mobileNo || '',
        }));

    }, [])

    const captureCaptcha = (event) => {
        setCaptchaValue(event.target.value)
    }

    const validateCaptcha = async () => {
        console.log('validate captcha', univID)

        try {
            const response = await fetch(ValidateCaptcha, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ dateOfBirth: dob, mobileNo: mobileNo, captchaValue: captchaValue, univId: parseInt(sessionStorage.getItem('univID')) }),
            });
            if (response.ok) {
                const data = await response.json();
                console.log(validateOtpData)
                if(data.isSuccess && validateOtpData && validateOtpData.isSuccess){
                    // setCaptchaVerified(true)
                    Navigate("/course-selection");
                } else if(data.isSuccess === false && data.data.captchaDetails.attempts === 0){
                    setOtp('')
                    setInpVal((prevInpVal) => ({
                        ...prevInpVal,
                        'mobile': ''
                    }));
                    setCaptchaValue('')

                    let captchaBlock = document.getElementById('captcha_block')
                    captchaBlock.style.display = 'none'

                    let block = document.getElementById('otpBlock');
                    block.style.display = 'none';

                    setMobileErrorMessage("Maximum attempts exceeded. Please request a new OTP to continue.")
                } else if(data.isSuccess && !validateOtpData ){
                    setOtpErrorMessage('OTP is required')
                } else {
                    setCaptchaErrorMessage(data.message)
                }
                
                // } else if (data.isSuccess === false && captchaEnable === 0) {
                    // setOtpErrorMessage(data.message)
                // }
            } else {
                setOtpErrorMessage('Invalid Captcha')
            }
        } catch (error) {
            console.log(error);
        }

    }

    const refreshCaptcha = async() => {
        try {
            const response = await fetch(GenerateOtp, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ dateOfBirth: dob, mobileNo, univID, captchaFlag: captchaEnable, refreshCaptchaFlag: 1 }),
            });
            if (response.ok) {
                const data = await response.json();
                setTempOtp(data.data)
                setCaptchaDetails(data.data.captchaDetails)
            } else {
                console.log("")
            }
        } catch (error) {
            console.log(error);
        }
    }

    // console.log(validateOtpData)

    // console.log('captchaEnable', captchaEnable)
    // console.log('captchaDetail', captchaDetails)

    return (
        <Grid item xs={12} sm={8} md={5} elevation={6} square>
            <Box sx={{ my: 8, mx: mobileView ? 4 : 10, direction: 'column', alignItems: 'center', justifyContent: "center" }}>
                <Box>
                    <Typography class="header" sx={{ textAlign: 'left', textTransform: 'none', fontWeight: 550 }} component="h1" variant="h6">
                        Thank you for showing interest
                    </Typography>
                    <Typography class="typography" sx={{ textAlign: 'left' }} mt={5}>
                        Register to complete the student application form
                    </Typography>
                    <Box component="form" noValidate sx={{ width: '87%' }}>
                        <InputLabel className='label' sx={{ mt: 3 }}>
                            Full name
                        </InputLabel>
                        <TextField
                            className='inputRequired'
                            required
                            fullWidth
                            id="full_name"
                            name="full_name"
                            autoComplete="off"
                            onChange={check}
                            value={inpVal.full_name}
                            sx={{
                                input: {
                                    width: '100%',
                                    // border: '0.5px solid #2B4DC0',
                                    borderRadius: '4px',
                                    height: '11px',
                                    borderLeft: '2px solid red'
                                }
                            }}
                            inputProps={{
                                maxLength: 100
                            }}
                            helperText={
                                <Typography className={nameErrorMessage ? 'error' : ''} id='full_name_error'>
                                    {nameErrorMessage}
                                </Typography>
                            }
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <InputLabel className='label'> Date of birth </InputLabel>
                            <DemoContainer components={['DatePicker', 'TimePicker', 'DateTimePicker', 'DateRangePicker',]}>
                                <DemoItem sx={{ marginTop: '0px !important', border: '0.5px solid #2B4DC0', }} componentName="DatePicker" name="dob" valueType="date">
                                    <DatePicker
                                        sx={{
                                            marginTop: '0px !important',
                                            input: { borderLeft: '2px solid red', borderRadius: '4px', height: '11px' }
                                        }}
                                        className='inputRequired'
                                        id='dob'
                                        format='DD/MM/YYYY'
                                        name="dob"
                                        disableFuture
                                        maxDate={year}
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        InputProps={{
                                            endAdornment: (
                                                <Typography>{age !== null ? `${age} years` : ''}</Typography>
                                            )
                                        }}

                                    />
                                    <Typography className={dateErrorMessage ? 'error' : ''} id='dob_error'>
                                        {dateErrorMessage}
                                    </Typography>
                                </DemoItem>
                            </DemoContainer>
                        </LocalizationProvider>

                        <InputLabel className='label' sx={{ mt: 3 }}> Enter your mobile number </InputLabel>
                        <TextField
                            className="inputRequired"
                            value={inpVal.mobile}
                            type="text"  // Changed to text for better control over length
                            required
                            fullWidth
                            id="mobile"
                            name="mobile"
                            autoComplete="off"
                            inputProps={{
                                maxLength: 10,         // Set max length for phone number input
                                pattern: '[0-9]*',     // Ensure only numeric characters are allowed
                            }}
                            sx={{
                                input: { height: '11px' }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <Button className="primary-btn" id="send_otp" sx={{ marginRight: '-3%', textTransform: 'none' }} variant="contained" onClick={showOtpField}>
                                        Send OTP
                                    </Button>
                                )
                            }}
                            onChange={check}
                            helperText={
                                <Typography sx={{ ml: 0 }} className={mobileErrorMessage ? 'error' : ''} id="mobile_error">
                                    {mobileErrorMessage}
                                </Typography>
                            }
                        />

                        <Box id="otpBlock" sx={{ display: 'none' }}>
                            <InputLabel className='label' sx={{ mt: 3 }}> Enter OTP sent to your mobile number </InputLabel>

                            <OtpInput
                                className='otpField'
                                type='number'
                                name='otp'
                                value={otp}
                                numInputs={4}
                                renderSeparator={<span>&emsp;</span>}
                                renderInput={(props) => <input {...props}
                                    style={{ width: '15%', height: '47px', textAlign: '-webkit-center' }}
                                />}
                                onChange={validateOtp}
                            />
                            <Typography sx={{ textAlign: 'left' }} id='timer' className={otpErrorMessage ? 'error' : ''}>
                                {timeout ? (
                                    <React.Fragment>
                                        <Box sx={{ textAlign: 'left', display: 'inline' }} id='resend_otp'>
                                            <HyperLink component="button" underline="none" sx={{ textAlign: 'left', mt: 3 }} onClick={(e) => { e.preventDefault(); resendOtp(); }}>
                                                Resend OTP
                                            </HyperLink>
                                        </Box>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {otpErrorMessage}
                                        <Box sx={{ display: 'inline' }} id='otp_timer'>
                                            <Typography className="label">Resend otp in <Typography sx={{ color: '#1976d2', display: 'inline' }}>{minutes}:{seconds}</Typography> </Typography>
                                        </Box>
                                    </React.Fragment>
                                )}

                            </Typography>

                            {/* <Box><Typography>{tempOtp}{resendTempOtp}</Typography></Box> */}
                        </Box>

                        {captchaEnable === 1 && captchaDetails !== null &&
                            <Box id='captcha_block' sx={{display: 'none'}}>
                            <Box sx={{display: 'flex',}}>
                                <img src={captchaDetails.captchaImage} alt='captcha' style={{float: 'left', marginTop: '8px'}}/>
                                <RefreshIcon sx={{mt:2, ml:1}} onClick={refreshCaptcha}/>
                            </Box>
                            <TextField
                                className="inputRequired"
                                value={captchaValue}
                                type="text"  // Changed to text for better control over length
                                required
                                fullWidth
                                id="captcha"
                                name="captcha"
                                autoComplete="off"
                                inputProps={{
                                    maxLength: 10,         // Set max length for phone number input
                                    pattern: '[0-9]*',     // Ensure only numeric characters are allowed
                                }}
                                sx={{
                                    mt: 2,
                                    input: { height: '11px' }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <Button className="primary-btn" id="verify_captcha" sx={{ marginRight: '-3%', textTransform: 'none' }} variant="contained" onClick={validateCaptcha}>
                                            Verify Captcha
                                        </Button>
                                    )
                                }}
                                onChange={captureCaptcha}
                                helperText={
                                    <Typography sx={{ ml: 0 }} className={captchaErrorMessage ? 'error' : ''} id="captcha_error">
                                        {captchaErrorMessage}
                                    </Typography>
                                }
                            />
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Grid>
    )
}